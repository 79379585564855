<template>
  <v-data-table
    :headers="headers"
    :footer-props="footerProps"
    :items="surveyDetails"
    :loading="isLoading"
    :server-items-length="surveysCount"
    :options.sync="options"
  >
    <template v-slot:item.title="{ item }">
      <router-link
        class="view-link"
        :to="{ name: 'SurveysView', params: { identity: item.identity } }"
      >
        {{ item.title }}
      </router-link>
    </template>
    <template v-slot:item.isActive="{ item }">
      <v-icon v-if="item.isActive" color="green">mdi-check-circle</v-icon>
      <v-icon v-else color="red">mdi-cancel</v-icon>
    </template>
    <template v-slot:item.isApproved="{ item }">
      <span v-if="item.isApproved">Approved</span>
      <span v-else>Draft</span>
    </template>
    <template v-slot:item.results="{ item }">
      <v-btn
        v-if="showExportMetricsButton"
        class="mx-1 export-btn"
        @click="exportMetrics(item.identity)"
      >
        <v-icon left dark class="mr-3"> mdi-tray-arrow-down </v-icon>
        Export Metrics
      </v-btn>
    </template>
    <template v-slot:no-data>
      <no-data-alert />
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import _ from "lodash";

export default {
  name: "SurveysTable",
  props: {
    // Allow for the user of this component to indicate loading since data used in this component
    // must be loaded externally (e.g. which surveys should be shown should be loaded externally
    // based on what is appropriate for the current scope). We want the external context to load data,
    //  determine when the necessary data is loaded, and give this component permission to show it
    isLoading: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  computed: {
    ...mapState(["footerProps"]),
    ...mapState("surveys", ["surveys", "surveyVersions", "surveysCount"]),
    ...mapGetters(["acceptRoles"]),
    surveyDetails() {
      const details = [];

      for (const survey of this.surveys) {
        details.push({
          ...survey,
          isApproved:
            this.surveyVersions[survey.oFormVersionId]?.isApproved ?? null,
        });
      }

      return details;
    },
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          let sortField = this.options.sortBy[i];
          if (sortField === "isApproved") {
            sortField = "OFormVersions.isApproved";
          }
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }

      return data;
    },
    showExportMetricsButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    headers() {
      let headers = [
        {
          text: "Name",
          value: "title",
          sortable: true,
        },
        {
          text: "Live",
          value: "isActive",
          sortable: true,
        },
        {
          text: "Status",
          value: "isApproved",
          sortable: true,
        },
      ];

      if (this.showExportMetricsButton) {
        headers.push({
          text: "Results",
          value: "results",
          sortable: false,
        });
      }
      return headers;
    },
  },
  watch: {
    pageAndSortData: {
      handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;

        this.$emit("updateSurveys", currentValue);
      },
      deep: true,
    },
  },
  components: {
    NoDataAlert,
  },
  methods: {
    exportMetrics(identity) {
      this.$emit("exportMetrics", identity);
    },
  },
};
</script>
<style scoped>
.export-btn {
  background-color: #da6028 !important;
  color: white;
}
</style>
